import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/Users/abdullatieffathoni/Code/javascript/tds-web/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const PageDescription = makeShortcode("PageDescription");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <PageDescription mdxType="PageDescription">
      <p><inlineCode parentName="p">{`Breadcrumb`}</inlineCode>{` Sebuah bantuan untuk navigasi, membantu user untuk tetap mengetahui keberadaan mereka di dalam page.`}</p>
    </PageDescription>
    <h2>{`Example`}</h2>
    <h3>{`Breadcrumb Default`}</h3>
    <p>{`Breadcrumb utama dengan 3 sub halaman.`}</p>
    <span {...{
      "className": "gatsby-resp-image-wrapper",
      "style": {
        "position": "relative",
        "display": "block",
        "marginLeft": "auto",
        "marginRight": "auto",
        "maxWidth": "850px"
      }
    }}>{`
      `}<span parentName="span" {...{
        "className": "gatsby-resp-image-background-image",
        "style": {
          "paddingBottom": "24.305555555555557%",
          "position": "relative",
          "bottom": "0",
          "left": "0",
          "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAFCAYAAABFA8wzAAAACXBIWXMAAAsSAAALEgHS3X78AAAAiklEQVQY02Nwr97JoF95gcGi8gSDS/UeBt2KSwz65WcYjBruMlj6pjII8nIyCIuKM4CAkJAQA9UA0YbZVx3g16u4yAd0Ia9z9V4uoAv59MvP8hrX3+Q1cQzgFuTn4RMRk2Am2lDHqn1MQAOZgAYyAQ1kBBrIBDSQybj+FpOZaziTIB83E9DAgfMyANX9JGylvTG3AAAAAElFTkSuQmCC')",
          "backgroundSize": "cover",
          "display": "block"
        }
      }}></span>{`
  `}<img parentName="span" {...{
        "className": "gatsby-resp-image-image",
        "alt": "breadcrumb",
        "title": "breadcrumb",
        "src": "/static/18e66bdad69d37bd9f29e8f0243c74f3/e5284/default.png",
        "srcSet": ["/static/18e66bdad69d37bd9f29e8f0243c74f3/7fc1e/default.png 288w", "/static/18e66bdad69d37bd9f29e8f0243c74f3/a5df1/default.png 576w", "/static/18e66bdad69d37bd9f29e8f0243c74f3/e5284/default.png 850w"],
        "sizes": "(max-width: 850px) 100vw, 850px",
        "style": {
          "width": "100%",
          "height": "100%",
          "margin": "0",
          "verticalAlign": "middle",
          "position": "absolute",
          "top": "0",
          "left": "0"
        },
        "loading": "lazy"
      }}></img>{`
    `}</span>
    <h3>{`Breadcrumb More`}</h3>
    <p>{`Breadcrumb dengan 3 sub halaman lebih.`}</p>
    <span {...{
      "className": "gatsby-resp-image-wrapper",
      "style": {
        "position": "relative",
        "display": "block",
        "marginLeft": "auto",
        "marginRight": "auto",
        "maxWidth": "850px"
      }
    }}>{`
      `}<span parentName="span" {...{
        "className": "gatsby-resp-image-background-image",
        "style": {
          "paddingBottom": "24.305555555555557%",
          "position": "relative",
          "bottom": "0",
          "left": "0",
          "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAFCAYAAABFA8wzAAAACXBIWXMAAAsSAAALEgHS3X78AAAAi0lEQVQY02Nwq97FoF95gcGi8gSDS/UeBt2KSwz65WcYjBruMlj6pjII8nIyCIuKM4CAkJAQA9UA0YZ5VO/g1Ku4yA90Ia9z9V4uoAv59MvP8hrX3+Q1cQzgFuTn4RMRk2Am2lD36p2MehUXmIAGMgENZAQayAQ0kMm4/haTmWs4kyAfNxPQwIHzMgDTUSRpDplKcQAAAABJRU5ErkJggg==')",
          "backgroundSize": "cover",
          "display": "block"
        }
      }}></span>{`
  `}<img parentName="span" {...{
        "className": "gatsby-resp-image-image",
        "alt": "breadcrumb",
        "title": "breadcrumb",
        "src": "/static/9a23ae394ea55773efbeff33bd0cec72/e5284/more.png",
        "srcSet": ["/static/9a23ae394ea55773efbeff33bd0cec72/7fc1e/more.png 288w", "/static/9a23ae394ea55773efbeff33bd0cec72/a5df1/more.png 576w", "/static/9a23ae394ea55773efbeff33bd0cec72/e5284/more.png 850w"],
        "sizes": "(max-width: 850px) 100vw, 850px",
        "style": {
          "width": "100%",
          "height": "100%",
          "margin": "0",
          "verticalAlign": "middle",
          "position": "absolute",
          "top": "0",
          "left": "0"
        },
        "loading": "lazy"
      }}></img>{`
    `}</span>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      